import { IModalReview } from "./modalType";

export const reviewsData: IModalReview[]=[
    {
        id:1,
        title:"“Great communication”",
        name:"Lucia, New South Wales",
        review:"“Great product and communication, could not have been happier with the service”"
    },
    {
        id:2,
        title:"“Fast Repairs​”",
        name:"Michael, Queensland​",
        review:"“We were able to start the roof repairs quickly. They explained the process in detail before starting.​”"
    },
    {
        id:3,
        title:"“Good investment”",
        name:"Jack, Queensland",
        review:"“The roof painting transformation was spectacular. Nothing makes a house feel more brand new than a new roof.​”"
    },
]