import React from 'react';
import TabsComponent from '../Tabs';
import styles from "./FAQ.module.scss";



const FAQSection = () => {
  return (
      <section className={styles.servicesSection}>
          <div className={styles.servicesWrapper}>
              <h2 className={styles.sectionTitle}>Frequently Asked Questions</h2>
             <TabsComponent />
      
          </div>
    </section>
  )
}

export default FAQSection