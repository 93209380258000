import { IRoofServicesData } from "./modalType";
import roofReplacement from "../../assets/roofReplacement.webp";
import roofRestoration from "../../assets/roofRestoration.webp";
import roofRepair from "../../assets/roofRepair.webp";



export const modalServicesData: IRoofServicesData[] = [
    {
    id: 1,
    img:roofReplacement,
    title: "Our Roof Replacement Services",
    subTitle1: "Reroofing / Roof Replacement",
    text1: "When it's time for a fresh start, trust Munro Roofing for reroofing and roof replacement. We use high-quality materials and expert craftsmanship to ensure a durable, reliable, and long-lasting roof over your head.",
    subTitle2: "Gutter and Fascia",
    text2:"Upgrade your entire roofing system with our professional gutter and fascia services. We'll ensure that your roof functions seamlessly, directing rainwater away from your home to prevent water damage.",
    subTitle3: "Gutter Protection",
    text3:"Say goodbye to clogged gutters with our advanced gutter protection solutions. Keep your gutters free from debris and extend their lifespan.",
    },
       {
    id: 2,
    img:roofRestoration,
    title: "Our Roof Restoration Services",
    subTitle1: "Full Roof Restoration",
    text1: "Restore your roof's former glory with our comprehensive roof restoration service. We'll address all aspects, from repairs to cleaning, making it look and perform like new.",
    subTitle2: "Roof Painting & Sealing",
    text2:"Enhance your roof's aesthetics and protect it from the elements with our expert roof painting and sealing services.",
    subTitle3: "Solar Panel Cleaning",
    text3:"Maximize your energy savings with clean and efficient solar panels. Our solar panel cleaning service ensures optimal performance.",
     subTitle4: "Roof High-Pressure Cleaning",
    text4:"Rejuvenate your roof's appearance and extend its life with our high-pressure cleaning services.",
    },
       {
    id: 3,
    img:roofRepair,
    title: "Our Roof Repair Services",
    subTitle1: "Roof Repair",
    text1: "No roofing issue is too small or too large for our expert team. We'll efficiently diagnose and repair any problems to extend your roof's lifespan. We have experience with every roof type including; broken tiles, leaks, water damage, fire damage, rusted gutters and sheeting.",
    subTitle2: "Pressure Washing",
    text2:"Revitalize your roof's appearance with our high-pressure washing services, removing stubborn stains, moss, and debris",
    },

]