import React, { useRef } from 'react';
import { Helmet } from 'react-helmet-async';
import HeroSection from '../../components/Hero';
import ServicesSection from '../../components/ServicesSection';
import Header from '../../components/Header';
import Form from "../../components/Form";
import WhyChooseSection from '../../components/WhyChooseSection';
import RoofServicesSection from '../../components/RoofServicesSection';
import TalkToExperts from '../../components/TalkToExperts';
import PartnersSection from '../../components/Partners';
import LearnMoreSection from '../../components/LearnMoreSection';
import FAQSection from '../../components/FAQ';
import TalkForFreeComponent from '../../components/TalkForFree';


const MainPage = () => {
     const formRef = useRef<HTMLDivElement>(null);

const scrollToRef=(ref: React.RefObject<HTMLElement | null>)=> {
  if (ref.current) {
    ref.current.scrollIntoView({ behavior: 'smooth' });
  }
}
  return (
    <>
    <header>
      <Helmet>
        <title>Munro Roofing - Replace, Restore, Repair - Roofing Experts in Australia</title>
        <meta
          name="description"
          content="Munro Roofing - Your trusted roofing experts providing quality services across Australia. We offer emergency roof repairs and certified professionals for all your roofing needs. Get a free roofing consultation from our expert team. Choose Munro Roofing for exceptional service."
        />
      </Helmet>
      <Header scrollToTarget={scrollToRef} formRef={formRef}/>
      </header>
      <main>
        <HeroSection />
        <ServicesSection />
        <div ref={formRef}>
          <Form />
        </div>
        <WhyChooseSection />
        <RoofServicesSection />
        <TalkToExperts scrollToTarget={scrollToRef} formRef={formRef}/>
        <PartnersSection />
        <LearnMoreSection scrollToTarget={scrollToRef} formRef={formRef}/>
        <FAQSection />
        <TalkForFreeComponent scrollToTarget={scrollToRef} formRef={formRef}/>
    </main>
    </>
  )
}

export default MainPage