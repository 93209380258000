import React, { FC } from 'react';
import { IHeaderProps } from '../../constants/commonTypes';
import styles from"./TalkForFree.module.scss";

const TalkForFreeComponent:FC<IHeaderProps> = ({ scrollToTarget, formRef }) => {

  return (
      <section className={styles.formSection}>
          <div className={styles.wrapper}>
             <h3 className={styles.talkTitle}>Talk to us for free to get started</h3>
            <p className={styles.talkText}>We are open 6 days a week from 8am to 6pm. We are ready to take your call and respond to all emails within 24 hours.</p>
            <div className={styles.btnWrapper}>
                <a href='tel:1300515024' className={styles.phone}>1300 515 024</a>
                      <button className={styles.button} onClick={() => scrollToTarget(formRef)}> Email us</button>
                </div>
          </div>
    </section>
  )
}

export default TalkForFreeComponent