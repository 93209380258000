import React, {  FC } from 'react';
import { IHeaderProps } from '../../constants/commonTypes';
import styles from"./TalkToExperts.module.scss";
import formImg from "../../assets/talkToExperts.webp";


const TalkToExperts: FC<IHeaderProps> = ({ scrollToTarget, formRef }) => {

  return (
      <section className={styles.formSection}>
          <div className={styles.wrapper}>
          <p className={styles.contactsTitleResponsive}>Talk to our experts to find the roofing service that is right for you.</p>
              <div className={styles.imgFormContainer}>
                  <img className={styles.imgForm} src={formImg} alt="form"/>
              </div>
              <div className={styles.contactsContainer}>
                <p className={styles.contactsTitle}>Talk to our experts to find the roofing service that is right for you.</p>
                <div className={styles.btnWrapper}>
                <a href='tel:1300515024' className={styles.phone}>1300 515 024</a>
                      <button className={styles.button} onClick={() => scrollToTarget(formRef)}> Email us</button>
                </div>
              </div>
            
          </div>
    </section>
  )
}

export default TalkToExperts