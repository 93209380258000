import React, {FC,  } from 'react';
import { IHeaderProps } from '../../constants/commonTypes';
import styles from"./LearnMoreSection.module.scss";
import formImg from "../../assets/gutterGuardLogo.svg";


const LearnMoreSection: FC<IHeaderProps> = ({ scrollToTarget, formRef }) => {

  return (
      <section className={styles.formSection}>
          <div className={styles.wrapper}>
              <div className={styles.imgFormContainer}>
                  <img className={styles.imgForm} src={formImg} alt="form"/>
              </div>
              <div className={styles.contactsContainer}>
                <p className={styles.contactsTitle}>Learn more about Munro Roofing's gutters and gutterguard product and installation</p>
                <div className={styles.btnWrapper}>
                  <button className={styles.button} onClick={() => scrollToTarget(formRef)}>Visit website</button>
                </div>
              </div>
            
          </div>
    </section>
  )
}

export default LearnMoreSection