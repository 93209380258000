import { createTheme } from '@mui/material';

const landingDefaultTheme = createTheme({
  components: {
    MuiTab:{
      styleOverrides: {
        root: {
          textTransform:'capitalize',
          fontFamily: 'Poppins, sans-serif',
          fontSize: '32px',
          fontStyle: 'normal',
          fontWeight: 400,
          lineHeight: '42px',
          width: '507px',
          maxWidth:"507px",
          height:"74px",
          borderRadius:'8px',
          color:"#333333",
          border: "1px solid #333333",
          marginRight:"40px",
          padding:"16px 98px",
          '&.Mui-selected': {
            color: "#fff",
            background:"#346348",
            maxWidth:"507px",
            width: '507px',
        },
        "@media(max-width:860px)":{
          padding:"16px",
          width: '320px',
          maxWidth:"320px",
          fontSize: '20px',
          lineHeight: '26px',
          height:"52px",
          '&.Mui-selected': {
            width: '320px',
            maxWidth:"320px",
        },
        }
        },
      },
    }
  },
});

export default landingDefaultTheme;
