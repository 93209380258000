import React from 'react';
import styles from "./ServicesSection.module.scss";
import { servicesCardsData } from '../../constants/ServicesCardData';
import ServicesCard from '../ServicesCard';


const ServicesSection = () => {
  return (
      <section className={styles.servicesSection}>
          <div className={styles.servicesWrapper}>
              <h2 className={styles.sectionTitle}>Our Services</h2>
        <div className={styles.cardsWrapper}>
          {servicesCardsData.map(item => (
              <ServicesCard id={item.id} title={item.title} description={item.description} src={item.src} modalTitle={item.modalTitle}/>
          ))} 
              </div>
          </div>
    </section>
  )
}

export default ServicesSection