import logo1 from "../../assets/partnersLogo/1.svg";
import logo2 from "../../assets/partnersLogo/2.svg"
import logo3 from "../../assets/partnersLogo/3.png"
import logo4 from "../../assets/partnersLogo/4.svg"
import logo5 from "../../assets/partnersLogo/5.svg"
import logo6 from "../../assets/partnersLogo/6.png"
import logo7 from "../../assets/partnersLogo/7.svg"
import { IPartnersData } from "./partnersType";

export const partnersData:IPartnersData[]=[
    {
        id:1,
        src:logo1,
        text:"We only use Dulux paint, the best quality paint in Australia."
    },
    {
        id:2,
        src:logo2,
        text:"Leading Australian manufacturer of steel roofing, walling, gutters and downpipes"
    },
    {
        id:3,
        src:logo3,
        text:"Metal coated and painted steel building products"
    },
    {
        id:4,
        src:logo4,
        text:"Colorbond steel in 22 colours"
    },
    {
        id:5,
        src:logo5,
        text:"We comply to all Australian building standards"
    },
    {
        id:6,
        src:logo6,
        text:"Proud to be a Housing Institute of Australia member"
    },
    {
        id:7,
        src:logo7,
        text:"Proud to be a Masters Builders Association member"
    },

    
]