import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import TagManager from 'react-gtm-module';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import RoutesApp from './routes';
import ScrollToTop from './utils/ScrollToTop';
import './App.scss';

declare global {
  interface Window {
    dataLayer: any[];
  }
}
const tagManagerArgs = {
  gtmId: 'GTM-PVVWR8C2'
}
TagManager.initialize(tagManagerArgs)

function App() {
  window.dataLayer.push({
    event: 'pageview'
  });
  
  return (
    <BrowserRouter>
      <ToastContainer />
       <ScrollToTop />
      <RoutesApp />
    </BrowserRouter>
  );
}

export default App;
