import React from 'react';
import styles from "./RoofServicesSection.module.scss";
import { roofServicesData } from '../../constants/RoofServices';
import DOMPurify from 'dompurify';
import ModalRoofServices from '../ModalRoofServices';



const RoofServicesSection = () => {
  const sanitizer = DOMPurify.sanitize;
  return (
      <section className={styles.servicesSection}>
        {roofServicesData.map(item=>(
          <div className={styles.servicesWrapper}>
            <div className={styles.leftContainer}>
              <h2 className={styles.sectionTitle}>{item.title}</h2>
              <div className={styles.imgRoofContainerResponsive}>
              <img src={item.src} alt="roof" />
            </div>
              <h3 className={styles.sectionSubTitle}>{item.subTitle}</h3>
              <p className={styles.sectionText}
                    dangerouslySetInnerHTML={{
                      __html: sanitizer(item.text),
                    }}
                  />
                  <ModalRoofServices children="Learn more >" id={item.id}/>
            </div>
            <div className={styles.imgRoofContainer}>
              <img src={item.src} alt="roof" />
            </div>
          </div>
        ))}
        
          
    </section>
  )
}

export default RoofServicesSection