import React from 'react';
import styles from "./Hero.module.scss"
import { logoCardsData } from '../../constants/Hero';



const HeroSection=() => { 
  return (
    <section className={styles.heroSection}>
      <div className={styles.upperContainer}>
         <div className={styles.heroWrapper}>
          <h1 className={styles.header}>Replace. Restore. Repair. Your Roofing experts.</h1>
          </div>
      </div>
      <div className={styles.bottomContainer}>
        <div className={styles.logoWrapper}>
          {logoCardsData.map((card, index) => (
            <div key={index} className={styles.logoCard}>
              <img src={card.img} alt={card.text} />
              <p>{card.text}</p>

            </div>
          ))}
        </div>
       </div>
         
    </section>
  )
}

export default HeroSection