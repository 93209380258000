import { IRoodServiceProps } from "./roofServicesTypes";
import service1 from "../../assets/roofServices/1.webp";
import service2 from "../../assets/roofServices/2.webp";
import service3 from "../../assets/roofServices/3.webp";



export const roofServicesData:IRoodServiceProps[]=[
{
    id:1,
    title:"Roof replacement",
    subTitle:"Increase your property’s value",
    text:`At Munro Roofing, we understand that a roof replacement is a significant investment in your property's future. We specialise in providing top-tier roofing solutions to elevate your home's aesthetic appeal and performance.<br/><br/><li>Reroofing</li><li> Gutter and Fascia</li><li> Gutter Protection</li>`,
    src:service1
},
{
    id:2,
    title:"Roof restoration​",
    subTitle:"Make your roof brand new again​",
    text:`If your roof is showing signs of wear and tear, Munro Roofing is here to breathe new life into it. Our roof restoration services are designed to enhance your roof's appearance and extend its longevity.<br/><br/><li>Full Roof Restoration</li><li>Roof Painting & Sealing</li><li>Solar Panel Cleaning</li><li>Roof High-Pressure Cleaning</li><li>Re-Pointing</li><li>Tile Replacements</li><li>Valley Iron Replacement</li><li>Eaves Replacements</li><li>Flashing Replacements</li><li>Pressure Washing</li>`,
    src:service2
},
{
    id:3,
    title:"Roof repair",
    subTitle:"Repairs to protect your home and your investment",
    text:`When roofing problems arise, Munro Roofing offers prompt and efficient roof repair services to safeguard your home and belongings.<br/><br/>
    Repairs can keep your roof functional and your home protected. Munro Roofing can handle emergency repairs from fire, storms, fallen trees, and leaks.<br/><br/><li>Roof repairs</li><li>Pressure Washing</li>`,
    src:service3
},

]