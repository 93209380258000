import { createSlice } from '@reduxjs/toolkit';

export const formSlice = createSlice({
  name: 'form',
  initialState: {
    firstName: '',
    lastName: '',
    phone: '',
    address: '',
    postCode: '',

  },
  reducers: {
    setFirstName: (state, { payload }) => ({ ...state, ...payload }),
    setLastName: (state, { payload }) => ({ ...state, ...payload }),
    setPhone: (state, { payload }) => ({ ...state, ...payload }),
    setAddress: (state, { payload }) => ({ ...state, ...payload }),
    setPostCode: (state, { payload }) => ({ ...state, ...payload }),
  },
});

// ACTIONS

export const {
  setFirstName,
  setLastName,
  setPhone,
  setAddress,
  setPostCode,
} = formSlice.actions;

// SELECTOR

export const selectForm = (state: any) => state.form;

// REDUCER

export default formSlice.reducer;
