import React, { FC, useState } from 'react';
import DOMPurify from 'dompurify';
import styles from './CardHover.module.scss';

interface IPropsCards{
  id:number,
  span:string,
  hoverText?:string,
  src?:string
}

const CardHover: FC<IPropsCards> = ({id, src, hoverText, span}) => {
  const [isHovered, setIsHovered] = useState(false);
  const sanitizer = DOMPurify.sanitize;

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  return (
         <div
                  className={`${styles.sliderCardServices} `}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                  key={id}
                >
                  <div className={styles.wrapperCard}>
                  <img src={src} alt={span} />
             <div className={`${isHovered===true ? styles.textHide : styles.imgTextContainer}`}>
               <p dangerouslySetInnerHTML={{
                      __html: sanitizer(span),
                    }} />
                    </div>
                  </div>
               
                  <div
                    className={`${
                      isHovered === false
                        ? styles.cardTextHoverHide
                        : styles.cardTextHoverShow
                    }`}
                  >
                    <span> {hoverText}</span>
                  </div>
                  </div>
   
   
  );
};

export default CardHover;
