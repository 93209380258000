import React, { FC, useState } from 'react';
import DOMPurify from 'dompurify';
import styles from "./ServicesCard.module.scss";
import arrowDown from "../../assets/arrowDown.svg";


interface IServiceCard {
    id: string,
    src: string,
    title:string,
    modalTitle:string,
    description:string
    
}


const ServicesCard:FC<IServiceCard> = ({id, src, title, modalTitle, description}) => {
    const [showMore, setShowMore] = useState(false);
    const sanitizer = DOMPurify.sanitize;
    return (
   
     
          <div className={`${styles.card} ${showMore && styles.expandedCard}`} key={id}>
                <div className={styles.imgContainer}>
                    <img src={src} alt="card-roof" />
                </div>
                <p className={styles.title}>{title}</p>
                    <div className={styles.contCard}>

                   
                   <p className={` ${showMore && styles.showLess} ${styles.btn}`}  onClick={() => setShowMore(!showMore)}>
                    {modalTitle}
                        <img src={arrowDown} alt="arrow" />
                    </p>
             
          {showMore ? <ul className={styles.description} dangerouslySetInnerHTML={{ __html: sanitizer(description), }} /> : <ul className={styles.description}>{description?.substring(0, 0)}</ul>}
          </div>
         
            </div>
       
  )
}

export default ServicesCard