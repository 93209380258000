import { IServiceCardProps } from "./servicesTypes";
import card1 from "../../assets/serviceCard/service1.webp";
import card2 from "../../assets/serviceCard/service2.webp";
import card3 from "../../assets/serviceCard/service3.webp"


export const servicesCardsData: IServiceCardProps[] = [
    {
        id: "1",
        title: "Entire roof or roof sections replaced",
        src: card1,
        modalTitle:"Roof Replacement Services",
        description:"<li>Reroofing/Roof Replacement</li> <li>Gutter and Fascia</li><li>Gutter Protection</li>"
    },
     {
        id: "2",
        title: "Renew appearance and increase longevity​",
         src: card2,
        modalTitle:"Roof Restoration Services",
        description:"<li>Full Roof Restoration</li><li>Roof Painting & Sealing</li><li>Pressure Washing</li><li>Solar Panel Cleaning</li><li>Roof High-Pressure Cleaning</li><li>Re-pointing</li><li>Tile Replacements</li><li>Scotia to Eaves</li><li>Valley Iron Replacement</li><li>Scotia Replacement</li><li>Flashing Replacement</li>"
    },
     {
        id: "3",
        title: "Repair aesthetic and structural damage​",
        src:card3,
         modalTitle: "Roof Repair Services",
        description:"<li>Roof Repair</li><li>Pressure Washing</li>"
    },
]
