import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import axios from 'axios';
import useDebounce from '../../hooks/useDebounce';
import styles from"./Form.module.scss";
import formImg from "../../assets/formImg.webp";
import ModalFormSent from '../ModalFormSent';
import { useDispatch, useSelector } from 'react-redux';
import { selectForm, setAddress, setFirstName, setLastName, setPhone, setPostCode } from '../../slices/form';


const Form = () => {
  const dispatch = useDispatch();
  const { firstName,lastName,phone,address, postCode} = useSelector(
    selectForm
  );

  const [firstNameValue, setFirstNameValue] = useState(firstName ? firstName : "");
  const [firstNameError, setFirstNameError] = useState('');
  const [lastNameValue, setLastNameValue] = useState(lastName? lastName :"");
  const [lastNameError, setLastNameError] = useState('');
  const [isEditPhone, setEditPhone] = useState(true); 
  const [phoneError, setPhoneError] = useState('');
  const [phoneValue, setPhoneValue] = useState(phone ? phone : "");
  const [addressValue, setAddressValue] = useState(address ? address : "");
  const [addressError, setAddressError] = useState('');
  const [postcodeValue, setPostcodeValue] = useState(postCode ? postCode : "");
  const [postcodeError, setPostcodeError] = useState('');
  const [showPopup, setShowPopup] = useState(false);

    const handleChangeFirstName = (val: ChangeEvent<HTMLInputElement>) => {
    const value = val.target.value;
    const valueEdited = value.replace(/[0-9]/g, '');
    setFirstNameValue(valueEdited)
     dispatch(setFirstName({ firstName: valueEdited } ));
     if (!value) {
      setFirstNameError('Please enter your first name');
    }
  };

  const handleChangeLastName = (val: ChangeEvent<HTMLInputElement>) => {
    const value = val.target.value;
    const valueEdited = value.replace(/[0-9]/g, '');
    setLastNameValue(valueEdited);
    if (!value) {
      setLastNameError('Please enter your last name');
    }
     dispatch(setLastName({lastName:valueEdited}));
  };
     const handleChangePhone = (val: ChangeEvent<HTMLInputElement>) => {
    const value = val.target.value || '';
    setPhoneError('');
    setPhoneValue(value);
    setEditPhone(false);
    if (!value) {
      setPhoneError('Please enter a valid phone number');
    }
  };

  const debouncedPhone = useDebounce(phoneValue, 500);

  useEffect(() => {
    if (debouncedPhone) {
      validatePhone();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedPhone]);
  
  const validatePhone = () => {
    setEditPhone(true);
    const regex = /^\d.*/;

  if (regex.test(phoneValue)) {
    setPhoneError('');

    dispatch(setPhone({ phone:phoneValue}));
  } 

   
  };
    const handleChangeAddress = (val: ChangeEvent<HTMLInputElement>) => {
   const value = val.target.value;
    setAddressValue(value);
    if (!value) {
      setAddressError('Please enter your address');
    }
     dispatch(setAddress({ address: value } ));
  };


    const handleChangePostcode = (val: ChangeEvent<HTMLInputElement>) => {
        const value = val.target.value;
        setPostcodeValue(value);
        if (!value) {
          setPostcodeError('Please enter your postcode');
        }
        dispatch(setPostCode({ postCode: value } ));
  }

  let data={
    "firstname":firstName,
    "lastname":lastName,
    "phone": phone,
    "postCode": postCode,
    "address":address,
    "notes": "",
    "email": "",
  }


   

  const handleSubmit=(e:FormEvent)=>{
    e.preventDefault();
    axios.post('https://mbg.gutterguard.company/api/Leads/CreateLead', {data})
        .then(

            (res) =>
            {
                if (!res) {
                    toast.error('Something went wrong',
                      );

                    return;
                }
                else {
                    toast.success('Successful',
                      );
                      setShowPopup(true);
                }

            // window.location.replace('https://www.gutterguard.company/submit/success')
        }
    ).catch((error) => {
        toast.error('Something went wrong', 
        );
        console.error(error);
    });
    // setShowPopup(true);
    // dispatch(setFirstName({ firstName: '' }));
    // dispatch(setLastName({ lastName: '' }));
    // dispatch(setPhone({ phone: '' } ));
    // dispatch(setPhone({ address: '' } ));
    // dispatch(setPhone({ postCode: '' } ));

  }

  const closeModal = () => {
    setShowPopup(false);
    //  dispatch(setFirstName({ firstName: '' }));
    // dispatch(setLastName({ lastName: '' }));
    // dispatch(setPhone({ phone: '' } ));
    // dispatch(setPhone({ address: '' } ));
    // dispatch(setPhone({ postCode: '' } ));
 

  };

  let fullName= `${firstName} ${lastName}`;

  const isDisabled =
    !phoneValue||
    !isEditPhone ||
    !!phoneError ||
    !firstNameValue ||
    !lastNameValue || 
    !addressValue || 
    !postcodeValue!;

    console.log('isDisabled :>> ', isDisabled);
    console.log('phoneValue :>> ', phoneValue);
    console.log('phoneError :>> ', phoneError);
    console.log('firstNameValue :>> ', firstNameValue);
    console.log('firstNameValue :>> ', lastNameValue);
    console.log('addressValue :>> ', addressValue);
    console.log('postcodeValue :>> ', postcodeValue);


  return (
      <section className={styles.formSection}>
          <div className={styles.wrapper}>
          <h3 className={styles.formTitleResponsive}>Get a free roofing consultation from our expert team.</h3>
              <div className={styles.imgFormContainer}>
                  <img className={styles.imgForm} src={formImg} alt="form"/>
              </div>
              <div className={styles.formContainer}>
                  <h3 className={styles.formTitle}>Get a free roofing consultation from our expert team.</h3>
                  <div className={styles.inputWrapper}>
                      <div className={styles.inputContainer}>
                           <input
                              id="firstName"
                              placeholder='First name *'
                              value={firstNameValue}
                                   onChange={handleChangeFirstName}
                                type="text"
                                className={`${styles.input} ${styles.inputName}`}
                            />
                                   <span className={styles.helperText}>{firstNameError}</span>
                      </div>
                    <div className={`${styles.inputContainer}`}>
                    <input
                        id="lastName"
                        placeholder='Last name *'
                        value={lastNameValue}
                           onChange={handleChangeLastName}     
                      type="text"
                      className={`${styles.input} ${styles.inputName}`}
                    />
                    <span className={styles.helperText}>{lastNameError}</span>
                  </div>
                  </div>
                   <div className={styles.nameWrapper}>
                 
                   <input
                      id="tel"
                         placeholder='Phone *'
                         value={phoneValue}
                       onChange={handleChangePhone}
                      type="tel"
                      className={`${styles.input}`}
                    />
                    <span className={styles.helperText}>{phoneError}</span>
                 
                  </div>
                  <div className={styles.inputWrapper}>
                      <div className={styles.inputContainer}>
                           <input
                              id="address"
                              placeholder='Address *'
                                   value={addressValue}
                                   onChange={handleChangeAddress}
                                type="text"
                                className={`${styles.input} ${styles.inputName}`}
                            />
                             <span className={styles.helperText}>{addressError}</span>
                      </div>
                    <div className={`${styles.inputContainer}`}>
                    <input
                    id="postcode"
                    placeholder='Post Code *'
                      value={postcodeValue}
                       onChange={handleChangePostcode}     
                      type="text"
                      className={`${styles.input} ${styles.inputName}`}
                    />
                     <span className={styles.helperText}>{postcodeError}</span>
                  </div>
                  </div>
                  <button className={`${styles.btnSubmit} ${isDisabled && styles.disabled}`} type="submit" onClick={handleSubmit} disabled={isDisabled}>Submit</button>
              </div>
             
          </div>
          {showPopup && (<ModalFormSent show={showPopup} onClose={closeModal} userName={fullName}/>
      )}
    </section>
  )
}

export default Form