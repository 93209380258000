import React from 'react';
import styles from "./Partners.module.scss";
import { partnersData } from '../../constants/Partners/PartnersData';



const PartnersSection = () => {
  return (
      <section className={styles.servicesSection}>
          <div className={styles.servicesWrapper}>
              <h2 className={styles.sectionTitle}>Our trusted partners</h2>
              <div className={styles.logosContainer}>
                {partnersData.map(card=>(
                   <div className={styles.partnerCard} key={card.id}>
                  <img src={card.src} alt="logo" className={`${card.id===6 && styles.imgAdditional}`}/>
                  <p className={styles.logoText}>{card.text}</p>
                </div>
                ))
               
}
              </div>
      
          </div>
    </section>
  )
}

export default PartnersSection