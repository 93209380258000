export const burgerMenuItemStyles={
    display:"flex",
    justifyContent:"center",
    marginBottom:"5px",
}
export const burgerBoxStyles={
    flexGrow: 1, 
    display:"none",
    maxWidth:"50px",
    "@media(max-width:860px)":{
        display:"flex",
    }
}