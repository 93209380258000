export const boxModalStyles = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "1600px",
    bgcolor: "#fff",
    outline: "none",
    borderRadius: "8px",
    padding: "32px",
    cursor: "pointer",
    "@media (max-width: 1600px)": {
        width: 1000,
        height:"700px",
        overflow:"auto",
    },
 
     "@media (max-width: 1000px)": {
        width:780,
     },
     "@media (max-width: 790px)": {
         width: 600,
     },
     "@media (max-width: 600px)": {
         width: 500,
     },
     "@media (max-width: 500px)": {
         width: 380,
     },
     "@media (max-width: 395px)": {
         width: 360,
     },

}

export const boxModalNeurodiversityStyles = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "1160px",
    height: '700px',
    bgcolor: "#fff",
    outline: "none",
    borderRadius: "20px",
    padding: "24px 40px",
    cursor: "pointer",
    "@media (max-width: 1180px)": {
        width: 800,
    },
    "@media (max-width: 860px)": {
        padding: "16px",
        width: "362px",
        height: '538px',
        overflow: "auto"
    },


}