import { IHoverCardProps } from "./hoverCardType";
import card1 from "../../assets/whyChooseCards/1.webp";
import card2 from "../../assets/whyChooseCards/2.webp";
import card3 from "../../assets/whyChooseCards/3.webp";
import card4 from "../../assets/whyChooseCards/4.webp";
import card5 from "../../assets/whyChooseCards/5.webp";
import card6 from "../../assets/whyChooseCards/6.webp";

export const chooseCardsData:IHoverCardProps[]=[
    {
    id:1,
    span:"30 years experience",
    hoverText:"Our team of seasoned professionals has the knowledge and skills to handle all your roofing needs.",
    src:card1
    },
    {
        id:2,
        span:"Quality materials",
        hoverText:"We use only the highest-quality materials to ensure the durability and longevity of your roof.",
        src:card2
        }, 
        {
            id:3,
            span:"Competitive pricing",
            hoverText:"We offer competitive prices without compromising on the quality of our work.",
            src:card3
            },
            {
                id:4,
                span:"Comprehensive services",
                hoverText:"From repairs to replacements, we offer a full range of roofing services under one roof.",
                src:card4,
                },
                {
                    id:5,
                    span:"Customer satisfaction",
                    hoverText:"Your satisfaction is our top priority. We're not satisfied until you are.",
                    src:card5
                    },
                    {
                        id:6,
                        span:"Trusted reputation",
                        hoverText:"Our satisfied customers can attest to our exceptional service and craftsmanship. Elevate your roofing experience and protect your investment with Munro Roofing's expert roofing services.",
                        src:card6
    },

]