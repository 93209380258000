
export const tabsStyles={
    width:"100%",
    display:"flex",
    "@media(max-width:1620px)":{
        width:"100%",
        '& .MuiTabs-flexContainer': {
            flexDirection:"column",
            gap:"20px",
          },
    }
}
export const tabPanelStyles={
    paddingTop:"60px",
    color:"#333333",
}