import React, { useState, FC } from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import { IModalProps } from '../../constants/Modals/modalType';
import { boxModalStyles } from '../../constants/styles/boxModal';
import styles from './ModalRoofServices.module.scss';
import { modalServicesData } from '../../constants/Modals/modalServicesData';


const ModalRoofServices: FC<IModalProps> = ({ children, id }) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  const check =modalServicesData.filter(item=>item.id ===id);


  return (
    <div>
      <p className={styles.btn}  onClick={handleOpen}>
        {children}
      </p>

      <Modal
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={boxModalStyles}>
                    {check &&  modalServicesData.filter(item=>item.id ===id).map(card => (
              <div className={styles.wrapper}>
                <div className={styles.containerLeft}>
                <h3 className={styles.title}>{card.title}</h3>
                        <p className={styles.subTitle}>{card.subTitle1}</p>
                        <p className={styles.subText}>{card.text1}</p>
                        <p className={styles.subTitle}>{card.subTitle2}</p>
                        <p className={styles.subText}>{card.text2}</p>
                        <p className={styles.subTitle}>{card.subTitle3}</p>
                        <p className={styles.subText}>{card.text3}</p>
                        {card.subTitle4 && <p className={styles.subTitle}>{card.subTitle4}</p>}
                        {card.text4 &&<p className={styles.subText}>{card.text4}</p>}
                  </div>

                           <div className={styles.containerRight} key={card.id}>
                         <img src={card.img} alt="card" />

                  </div>
               
              </div>
                    ))}


          </Box>
        </Fade>
      </Modal>
    </div>
  );
};
export default ModalRoofServices;
