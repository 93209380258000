import React, { useRef } from 'react'
import { Helmet } from 'react-helmet-async';
import DOMPurify from 'dompurify';
import Header from '../../components/Header';
import { termsData } from '../../constants/TermsAndConditions/termsData';
import styles from "./TermAndConditions.module.scss";

const TermAndConditions = () => {
    const formRef = useRef<HTMLDivElement>(null);

    const scrollToRef=(ref: React.RefObject<HTMLElement | null>)=> {
      if (ref.current) {
        ref.current.scrollIntoView({ behavior: 'smooth' });
      }
    }
    const sanitizer = DOMPurify.sanitize;
  return (
    <>
      <header>
        <Helmet>
        <title>Munro Building Group - Terms and Conditions</title>
        <meta
          name="description"
          content="Read our Terms of Service to understand the rules and conditions for using the Munro Building Group website and services. By using our site, you agree to comply with these terms."
        />
      </Helmet>
        <Header scrollToTarget={scrollToRef} formRef={formRef}/>
        </header>
        <main>
        <section className={styles.servicesSection}>
          <div className={styles.servicesWrapper}>
            <h2 className={styles.privacyTitle}>Terms and Conditions</h2>
            {termsData.map(item=>(
              <div key={item.id} className={styles.SectionTextContainer}>
              <p className={styles.sectionTitle}>{item.section}</p>
              <p className={styles.text} dangerouslySetInnerHTML={{
                      __html: sanitizer(item.text),
                }} 
              />
              </div>
            ))}
          </div>
        </section>
        </main>
    </>
  )
}

export default TermAndConditions