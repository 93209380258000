import React from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { footerLinks } from '../../constants/Footer/footerData';
import styles from "./Footer.module.scss";
import logo from "../../assets/logoFooter.svg"

const Footer = () => {
  const navigate=useNavigate();
  return (
    <footer>
        <div className={styles.footerSection}>
          <div className={styles.footerWrapper}>
            <div className={styles.footerLogoContainer} onClick={()=>navigate("/")}>
                <img src={logo} alt="logo" />
            </div>
            <ul className={styles.footerLinksContainer}>
                <p className={styles.titleContainer}>Links</p>
                {footerLinks.map(link=>(
                     <li className={styles.contactItem} key={link.page}>
                     <NavLink to={link.url} className={styles.link}>
                       {link.page}
                     </NavLink>
                   </li>
                ))}

            </ul>
            <ul className={styles.footerContactsContainer}>
                <p className={styles.titleContainer}>Contact us</p>
               <p className={styles.contactItem}>Areas serviced: Australia wide</p>
               <p className={styles.contactItem}>Phone: <a href='tel:1300515024' className={styles.phone}>1300 515 024</a></p>
               <p className={styles.contactItem}>Email us: <a
                  type="submit"
                  href="mailto:info@munrobuildinggroup.com.au "
                  className={styles.email}
                >
                  info@munrobuildinggroup.com.au 
                </a></p>
                <p className={styles.contactItem}>ABN 76 468 841 780</p>

            </ul>
            <ul className={styles.footerScheduleContainer}>
                <p className={styles.titleContainer}>Opening hours</p>
                <p className={styles.contactItem}>We are open 6 days a week</p>
                <p className={styles.contactItem}>8am to 6pm</p>

            </ul>
            <ul className={styles.footerOfficeContainer}>
                <p className={styles.titleContainer}>Head office</p>
                <p className={styles.contactItem}>3 Old Regret Road,
                Clifton Grove, Orange 2800</p>
            </ul>
            <ul className={styles.footerLocationsContainer}>
                <p className={styles.titleContainer}>Roofer locations</p>
                <p className={styles.contactItem}>New South Wales,
Canberra ACT,
NT,<br/>
Queensland,
Victoria,<br/>
South Australia,
Western Australia,
Tasmania</p>
            </ul>
          </div>
        </div>
        </footer>
  )
}

export default Footer