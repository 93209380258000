import { IHeroProps } from "./heroTypes";
import icon1 from "../../assets/certified-icon.svg";
import icon2 from "../../assets/roof icon.svg";
import icon3 from "../../assets/roof icon2.svg";
import icon4 from "../../assets/icon Australia.svg";



export const logoCardsData:IHeroProps[] = [
    {
        img:icon1,
        text: "Leaders in quality" 
    },
     {
        img:icon2,
        text: "Emergency roof repairs" 
    },
     {
        img:icon3,
        text: "Certified roofing professionals" 
    },
     {
        img:icon4,
        text: "Services across Australia" 
    },
   
]