import * as React from 'react';
import DOMPurify from 'dompurify';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { tabPanelStyles, tabsStyles } from '../../constants/styles/boxTabs';
import { faqData } from '../../constants/FAQ/FAQData';
import styles from "./TabsComponent.module.scss";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
  sx?:object;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={tabPanelStyles}>
          <Typography >{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const TabsComponent=()=> {
  const [value, setValue] = React.useState(0);
  const sanitizer = DOMPurify.sanitize;

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box>
        <Tabs sx={tabsStyles} value={value} onChange={handleChange} aria-label="basic tabs example"  TabIndicatorProps={{
    style: { display: 'none' }
  }}>
          <Tab label="Roof Replacement" {...a11yProps(0)} />
          <Tab label="Roof Restoration​" {...a11yProps(1)} />
          <Tab label="Roof Repair​" {...a11yProps(2)} />
        </Tabs>
      </Box>
      {faqData.map((item, index)=>(
        <CustomTabPanel value={value} index={index} key={index}>
        <h4 className={styles.tabTitle}>{item.title1}</h4>
        <p className={styles.tabText}  dangerouslySetInnerHTML={{
                      __html: sanitizer(item.text1),
                    }} />
        <h4 className={styles.tabTitle}>{item.title2}</h4>
        <p className={styles.tabText} dangerouslySetInnerHTML={{
                      __html: sanitizer(item.text2),
                    }} />
        <h4 className={styles.tabTitle}>{item.title3}</h4>
        <p className={styles.tabText} dangerouslySetInnerHTML={{
                      __html: sanitizer(item.text3),
                    }} />
                  {item.title4 &&  <h4 className={styles.tabTitle}>{item?.title4}</h4>}
       {item.text4 && <p className={styles.tabText} dangerouslySetInnerHTML={{
                      __html: sanitizer(item?.text4),
                    }} />}
                      {item.title5 &&  <h4 className={styles.tabTitle}>{item?.title5}</h4>}
       {item.text5 && <p className={styles.tabText} dangerouslySetInnerHTML={{
                      __html: sanitizer(item?.text5),
                    }} />}
                     {item.title6 &&  <h4 className={styles.tabTitle}>{item?.title6}</h4>}
       {item.text6 && <p className={styles.tabText}  dangerouslySetInnerHTML={{
                      __html: sanitizer(item?.text6),
                    }} />}
                     {item.title7 &&  <h4 className={styles.tabTitle}>{item?.title7}</h4>}
       {item.text7 && <p className={styles.tabText}  dangerouslySetInnerHTML={{
                      __html: sanitizer(item?.text7),
                    }} />}
                     {item.title8 &&  <h4 className={styles.tabTitle}>{item?.title8}</h4>}
       {item.text8 && <p className={styles.tabText}  dangerouslySetInnerHTML={{
                      __html: sanitizer(item?.text8),
                    }} />}
      </CustomTabPanel>
      ))}
    </Box>
  );
}
export default TabsComponent;