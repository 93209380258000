import React from 'react';
import styles from "./WhyChooseSection.module.scss";
import CardHover from '../CardHover';
import { chooseCardsData } from '../../constants/HoverCard';


const WhyChooseSection = () => {
  return (
      <section className={styles.servicesSection}>
          <div className={styles.servicesWrapper}>
              <h2 className={styles.sectionTitle}>Why choose Munro Roofing?</h2>
              <div className={styles.cardsWrapper}>
                {chooseCardsData.map(item=>(

                <CardHover id={item.id} src={item.src} span={item.span} hoverText={item.hoverText}/>
                ))}
              </div>
          </div>
    </section>
  )
}

export default WhyChooseSection