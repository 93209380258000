import React, { FC,  } from 'react';
import { reviewsData } from '../../constants/Modals/modalReviewsData';
import styles from './ModalFormSent.module.scss';
import thanksImg from "../../assets/modalSend.webp";
import stars from "../../assets/stars.svg";


export interface IModalSentProps {
  children?: JSX.Element | string,
  classes?: string;
  userName:string;
  show: boolean;
  onClose: () => void;

}

const ModalFormSent: FC<IModalSentProps> = ({  show, onClose, userName }) => {

  return (
    <div>
       {show &&(
          <div className={styles.modalBackdrop} onClick={onClose}>
         <div className={styles.modal}>
         <div className={styles.commonWrapper}>
          <div className={styles.upperContainer}>
          <div className={styles.thanksImgContainer}>
            <img src={thanksImg} alt="roof" />
          </div>
          <div className={styles.textContainer}>
            <p  className={styles.title}>Thank you {userName} for your interest in Munro Building Group.</p>
            <p  className={styles.text}>We respond to all requests within 2 hours.</p>
            </div>
          </div>
           
         
           <div className={styles.bottomContainer}>
            <p className={styles.reviewHeader}>What our customers are saying about us.</p>
            <div className={styles.reviewContainer}>
              {reviewsData.map(review=>(
             <div className={styles.reviewCardContainer} key={review.id}>
              <p  className={styles.reviewCardTitle}>{review.title}</p>
              <img src={stars} alt="stars" />
              <p  className={styles.reviewCardTitle}>{review.name}</p>
              <p className={`${styles.reviewCardTitle} ${styles.italic}`}>{review.review}</p>
             </div>

           ))}
            </div>
           
          </div>
         </div>
       </div>
       </div>
       )}
                 
    </div>
  );
};
export default ModalFormSent;
