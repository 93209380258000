import React, { FC, MouseEvent, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Menu, MenuItem } from '@mui/material';
import useSize from '../../hooks/useSize';
import { navbarData } from '../../constants/Navbar';
import { IHeaderProps } from '../../constants/commonTypes';
import { burgerBoxStyles, burgerMenuItemStyles } from '../../constants/styles/burgerMenuItemStyles';
import styles from "./Header.module.scss";
import logo from "../../assets/logo.svg";
import burgerImg from "../../assets/menuIcon.svg";


const Header: FC<IHeaderProps> = ({ scrollToTarget, formRef })=> {
    const {pathname}=useLocation();
    const { width } = useSize();
    const navigate=useNavigate();
    const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
    
    const handleOpenNavMenu = (event: MouseEvent<HTMLElement>) => {
        setAnchorElNav(event.currentTarget);
      };
    
      const handleCloseNavMenu = () => {
        setAnchorElNav(null);
      };

  return (
      <>
        <div className={styles.upperNav}>Munro Roofing: Australia’s trusted roofing experts</div>
        <div className={styles.middleNav} >
            <div className={styles.middleNavContainer} >
                {navbarData.map((item, index) => (
                    <p key={index} className={styles.middleItem} >{item.title}</p>
                ))}
            </div>  
          </div>
          <div className={`${styles.bottomNav}`}>
              <div className={styles.middleLogoNavContainer}>
                  <div className={styles.logoContainer}>
                      <img src={logo} alt="logo" onClick={()=>navigate("/")}/>
                  </div>
                  <div className={`${ width > 860 ? styles.contactsContainer : styles.contactsContainerHide}`}>
                      <a href='tel:1300515024' className={styles.phone}>1300 515 024</a>
                      {(pathname==="/privacy-policy" || pathname==="/terms-and-conditions") ? <a
                  type="submit"
                  href="mailto:info@munrobuildinggroup.com.au "
                  className={styles.button}
                >Email us</a> : <button className={styles.button} onClick={() => scrollToTarget(formRef)}> Fast free quote</button>}
                      
                  </div>      
          <Box sx={burgerBoxStyles}>
          <img src={burgerImg} alt="menu" onClick={handleOpenNavMenu}/>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
               <MenuItem sx={burgerMenuItemStyles} onClick={handleCloseNavMenu}>
                <a href='tel:1300515024' className={styles.phone}>1300 515 024</a>
                </MenuItem>
                <MenuItem sx={burgerMenuItemStyles} onClick={handleCloseNavMenu}>
                {(pathname==="/privacy-policy" || pathname==="/terms-and-conditions") ? <a
                  type="submit"
                  href="mailto:info@munrobuildinggroup.com.au "
                  className={styles.button}
                >Email us</a> : <button className={styles.button} onClick={() => scrollToTarget(formRef)}> Fast free quote</button>}
                </MenuItem>
            </Menu>
          </Box> 
              </div>
           </div>
    </>
  )
}

export default Header