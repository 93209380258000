import { INavberProps } from "./navbarTypes";


export const navbarData:INavberProps[] = [
    {
        title: "Emergency roof enquiries" 
    },
    {
        title: "Insurance quotes" 
    },
    {
        title: "Leaks" 
    },
    {
        title: "Wind damage" 
    },
     {
        title: "Fire damage" 
    },
]