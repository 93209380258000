import React, { useRef } from 'react'
import { Helmet } from 'react-helmet-async';
import Header from '../../components/Header';
import styles from "./PrivacyPolicy.module.scss";

const PrivacyPolicy = () => {
    const formRef = useRef<HTMLDivElement>(null);

    const scrollToRef=(ref: React.RefObject<HTMLElement | null>)=> {
      if (ref.current) {
        ref.current.scrollIntoView({ behavior: 'smooth' });
      }
    }
  return (
    <>
      <header>
     <Helmet>
        <title>Munro Building Group - Privacy Policy</title>
        <meta
          name="description"
          content="Protecting your privacy is important to Munro Building Group. Read our Privacy Policy to learn how we handle your personal information in accordance with the Australian Privacy Principles and the Privacy Act 1988."
        />
      </Helmet>
        <Header scrollToTarget={scrollToRef} formRef={formRef}/>
        </header>
        <main>
        <section className={styles.servicesSection}>
          <div className={styles.servicesWrapper}>
            <h2 className={styles.privacyTitle}>Privacy Policy</h2>
             <p className={styles.text}>Welcome to the Munro Building Group website. The Munro Building Group website is owned and operated by Greg Munro Building Services Pty Ltd ("Greg Munro Building Services," "we," or "us") on behalf of itself and its related bodies corporate. </p>
             <ol className={styles.privacyList}>
                <li>Protecting your privacy We are dedicated to safeguarding your privacy and upholding the privacy principles outlined in the Australian Privacy Principles under the Privacy Act 1988 ("the Privacy Act"). Below, you will find the information we are required to provide you with as a user of the Munro Building Group website, in accordance with the Australian Privacy Principles. By accessing and using the Munro Building Group website or by providing us with information, you consent to the usage and disclosure of your personal information as described in this Privacy Statement. We recommend keeping a copy of this Privacy Statement for future reference. </li>
                <li>Your personal information We may hold various types of personal information about you, including your name, date of birth, current and previous addresses, phone number, email address, and occupation. Occasionally, we may request personal information about other individuals, such as your customers or authorized representatives. If you choose to share this information with us, we rely on you to inform those individuals that their personal information is being provided to us. If you opt not to provide personal information, we may not be able to grant you access to certain parts of the Munro Building Group website or our other products and services. When lawful and practical, you have the option to remain anonymous or use a pseudonym. However, choosing to remain anonymous or use a pseudonym may limit access to some or all of the Munro Building Group website or our other products and services. </li>
                <li>When we collect personal information We will only collect your personal information when it is reasonably necessary for us to fulfill our functions or activities, or when required by law. Sensitive personal information (e.g., race, ethnicity, health information, etc.) requires enhanced protection under the Privacy Act. We will collect such sensitive information only with your consent and when it is reasonably necessary for our functions or activities. We will not seek your consent to collect sensitive information when it is required or authorized by law.</li>
                <li>How we collect personal information We will collect personal information about you through lawful and fair means. Typically, we will collect personal information directly from you, such as when you send us an email or complete and submit an online form. Exceptions include cases where you have consented to our collection of personal information from third parties (e.g., related companies, credit reporting agencies, or your representatives) or when legally required, or it is unreasonable or impractical to do so. </li>
                <li>If we receive unsolicited personal information If we receive unsolicited personal information about you that we could have collected if we had solicited it, we will treat it in the same manner as information collected under clauses 3 and 4 and in accordance with the Australian Privacy Principles. If we receive unsolicited personal information that we could not have collected, and it is not contained in a Commonwealth record, we will, if lawful and reasonable, either destroy the information or de-identify it. </li>
                <li>How we use your personal information We may use your personal information for various purposes, including providing access to the Munro Building Group website, responding to queries submitted through the website, notifying you of business or product changes, conducting internal research and development, complying with legal requirements, and enforcing agreements or participating in legal proceedings between you and us. If you are a registered user of the Munro Building Group website, we may use or disclose your personal information for direct marketing purposes when you expect to receive such material. In such cases, your consent is assumed for the use and disclosure of your personal information for direct marketing. If you do not wish to receive direct marketing materials, you can opt out at the time of providing your personal information. To cancel your consent, please request that we refrain from sending direct marketing communications to you through your user preferences page or contact our Privacy Officer via <a
                  type="submit"
                  href="mailto:info@munrobuildinggroup.com.au "
                  className={styles.email}
                >
                  info@munrobuildinggroup.com.au 
                </a></li>
                <li>Help us ensure accurate information We take reasonable steps to ensure the accuracy, completeness, and currency of the personal information we collect, use, and disclose. However, the accuracy of the information we hold depends largely on the information you provide. Please assist us by informing us of any errors in the information we have about you and keeping us updated with changes to your information, such as changes to your name or address. </li>
                <li>How to access your personal information You have the right to access your personal information, subject to some legal exceptions. If you would like to do so, please inform us. For security reasons, we may request that you submit your request in writing. We may charge a reasonable fee for searching and providing access to your information.</li>
             </ol>
       
          </div>
    </section>
        </main>
    </>
  )
}

export default PrivacyPolicy