import React, { FC, ReactNode } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import MainPage from '../pages/MainPage';
import PrivacyPolicy from '../pages/PrivacyPolicy';
import TermAndConditions from '../pages/TermAndConditions';
import Layout from '../components/Layout';

interface MyComponentProps {
  children: ReactNode;
}

const ScrollToTop: FC<MyComponentProps> = ({ children }) => {
  const { pathname } = useLocation();

  React.useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, [pathname]);

  return <>{children}</>;
};

const RoutesApp: FC = () => {
  return (
    <ScrollToTop>
      <Layout>
      <Routes>
        <Route path="" element={<MainPage />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-and-conditions" element={<TermAndConditions />} />
      </Routes>
      </Layout>
      
    </ScrollToTop>
  );
};

export default RoutesApp;
